<template>
  <!-- 转让信息模块 -->
  <div class="transferInfo">
    <!-- 新原型与一期冲突 原需求：中小企为转让人信息 其他资金方为转让信息  新原型统一为转让信息   2022/6/20 岳成材 -->
    <title-center name='转让信息'></title-center>
    <div class="pledgor">
      <div class="pledgor_top">
        <div v-if="pledgorList.length > 0">
          <div class="item"> 转让人名称：{{pledgorList[0].name}}</div>
          <div class="item">统一社会信用代码：{{pledgorList[0].creditCode}}</div>
        </div>
        <div class="addbtn" v-if="!isDetail">
          <div class="addbutton" @click="addAlienatorData">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <!-- 表格数据操作 -->
      <base-table
        :columns="alienatorForm"
        :tableAttrs="{
          data: alienatorData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="finContractNameHeader">
          <font style="color:#EE1C1C;">*</font>合同名称
        </template>
        <template slot="finContractNamesHeader">
          <font style="color:#EE1C1C;">*</font>实际合同名称
        </template>
        <template slot="pledgeValueHeader">
          <font style="color:#EE1C1C;">*</font>转让价值(元)
        </template>
        <template slot="relLoanAmountHeader">
          <font style="color:#EE1C1C;">*</font>对应融资额(元)
        </template>
         <template slot="pledgeRateHeader" v-if="dictType==='4'">
        <font style="color: #ee1c1c">*</font>质押率(%)
      </template>
        <template slot="finContractName" slot-scope="scope">
          <base-select
            v-model="scope.row.finContractId"
            filterable
            @change="finContractChange(scope.row, scope.row.finContractId,pledgorList[0] ? pledgorList[0].contractData:[],alienatorData,scope.$index)"
            :options="pledgorList[0]?pledgorList[0].contractData:[]"
            :selectedField="['keyId', 'contractName']"
            :disabled="isDetail"
          ></base-select>
        </template>
        <template slot="finContractNames" slot-scope="scope">
          <sz-input
            v-model="scope.row.finContractName"
            placeholder="请输入"
            :disabled="isDetail"
          ></sz-input>
        </template>
        <template slot="projectDebtor" slot-scope="scope">
          <sz-input
            v-model="scope.row.projectDebtor"
            placeholder="请输入"
            :disabled="isDetail"
          ></sz-input>
        </template>
        <template slot="finContractCode" slot-scope="scope">
          <sz-input
            v-model="scope.row.finContractCode"
            placeholder="请输入"
            :disabled="true"
          ></sz-input>
        </template>
        <template slot="finContractAmount" slot-scope="scope">
          <amount-input
            v-model="scope.row.finContractAmount"
            @change="change"
            :disabled="isDetail"
            placeholder="请输入"
            type='1000Y'
            @input='finContractAmountInput(scope.row.finContractAmount,scope.$index)'
          ></amount-input>
        </template>
        <template slot="pledgeValue" slot-scope="scope">
          <amount-input
            v-model="scope.row.pledgeValue"
            placeholder="请输入"
            :disabled="isDetail"
           type='1000Y'
             @input='pledgeValueChange(scope.row.pledgeValue,scope.$index)'
          ></amount-input>
        </template>
        <template slot="relLoanAmount" slot-scope="scope">
          <amount-input
            v-model="scope.row.relLoanAmount"
            placeholder="请输入"
            :disabled="isDetail"
           type='1000Y'
             @input='changeRelLoanAmount(scope.row.relLoanAmount,scope.$index)'
          ></amount-input>
        </template>
          <template slot="pledgeRate" slot-scope="scope" v-if="dictType==='4'">
        <amount-input
          v-model="scope.row.pledgeRate"
          placeholder="请输入"
          :disabled='true'
         type='Y'
        ></amount-input>
      </template>

        <template slot="action" slot-scope="scope" v-if="!isDetail">
          <IconButton
            content="删除"
            @click="removeAlienatorData(scope.$index)"
            icon="iconfont iconshanchu1"
          ></IconButton>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import SzInput from '@/components/input/sz-input/sz-input.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import titleCenter from '../../components/title-center.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseSelect from '@/components/common/base-select/new-base-select.vue'
import { alienatorForm } from '../utils/config'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export default {
  components: { titleCenter, BaseTable, IconButton, BaseSelect, AmountInput, SzInput },
  name: 'transferInfo',
  props: {
    dictType: String,
    pledgorList: Array,
    alienatorData: Array,
    contractInfoData: Array, // 补充合同数据
    finBusuinessInfo: Object, // 详情业务数据
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    alienatorForm () {
      return alienatorForm(this)
    }
  },
  methods: {
    // 合同对价
    finContractAmountInput (data, index) {
      // if (data - 10000000000 > 0) {
      //   this.warning('合同对价不能大于10亿')
      //   this.$set(this.alienatorData[index], 'finContractAmount', '')
      // }
    },
    // 对应融资额修改
    changeRelLoanAmount (value, index) {
      console.log(this.alienatorData[index].pledgeValue === true)
      if (value && this.alienatorData[index].pledgeValue) {
        this.$set(this.alienatorData[index], 'pledgeRate', (value / this.alienatorData[index].pledgeValue * 100).toFixed(2))
      }
      if (!value) {
        this.$set(this.alienatorData[index], 'pledgeRate', '')
      }
    },
    // 转让价值修改
    pledgeValueChange (value, index) {
      if (value === '0' || !value) {
        this.warning('请输入转让价值且转让价值必须大于0')
        return false
      }
      if (value && this.alienatorData[index].relLoanAmount) {
        this.$set(this.alienatorData[index], 'pledgeRate', (this.alienatorData[index].relLoanAmount / value * 100).toFixed(2))
      }
    },
    change (val) {
      console.log('chufa', val)
    },
    // 选择合同企业
    finContractChange (data, item, list, dataList, index) {
      let status = true
      if (this.contractInfoData) {
        const isSelectCode = this.contractInfoData.some(item => item.finContractId === data.finContractId)
        if (isSelectCode) {
          this.$set(data, 'finContractId', '')
          return this.warning('合同不能相同，请重新选择')
        }
      }
      dataList.forEach((ele, indexs) => {
        if (ele.keyIds === item && index !== indexs) {
          status = false
        }
      })
      if (!status) {
        this.$set(data, 'finContractId', '')
        return this.warning('合同不能相同，请重新选择')
      }

      list.forEach((ele) => {
        if (ele.keyId === item) {
          data.projectDebtor = ele.xmgsName
          data.finContractCode = ele.contractCode
          data.finContractAmount = ele.contractAmount
          this.$set(data, 'finContractName', ele.contractName)
          data.contractName = ele.contractName
          data.pledgeValue = ele.pledgeValue
          data.keyIds = ele.keyId // 用户补充合同查询过滤
        }
      })
      if (!item) {
        data.projectDebtor = ''
        data.finContractCode = ''
        this.$set(data, 'finContractName', '')
        data.finContractAmount = ''
        data.contractName = ''
        data.pledgeValue = ''
        data.relLoanAmount = ''
        data.keyIds = ''
      }
      this.$emit('clearRepaymentPlan', '')
    },
    // 新增转让信息
    addAlienatorData () {
      const data = {
        finContractName: '',
        projectDebtor: '',
        finContractId: '',
        finContractCode: '',
        finContractAmount: '',
        pledgeValue: '',
        relLoanAmount: '',
        gysId: this.finBusuinessInfo.gysId
      }
      this.alienatorData.push(data)
      if (this.dictType === '4') {
        this.$emit('clearRepaymentPlan', '')
      }
    },
    // 删除转让信息
    removeAlienatorData (index) {
      this.alienatorData.splice(index, 1)
      if (this.dictType === '4') {
        this.$emit('clearRepaymentPlan', '')
      }
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
